<template>
  <b-row>
    <b-col>
      <b-card title="Autorización de turno">
        <b-row>
          <b-col md="4">
            <div class="form-group">
              <label>Código</label>
              <input
                v-model="form.cod"
                disabled
                type="text"
                class="form-control form-control-sm"
                placeholder="Código"
              />
            </div>
          </b-col>
          <b-col md="4" v-if="false">
            <div class="form-group">
              <label>Recurso</label>
              <input
                v-model="form.placa"
                disabled
                type="text"
                max="6"
                class="form-control form-control-sm"
                placeholder="Placa"
              />
            </div>
          </b-col>
          <b-col md="4">
            <div class="form-group">
              <label>Observación</label>
              <input
                v-model="form.descripcion"
                disabled
                type="text"
                class="form-control form-control-sm"
                placeholder="Example: 14:00 a 22:00"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <label>Jornada</label>
            <select
              class="form-control form-control-sm"
              v-model="form.tipo"
              disabled
            >
              <option :value="null">Seleccione</option>
              <option
                :value="item.id"
                v-for="(item, idx) in journey"
                :key="idx"
              >
                {{ item.nombre }}
              </option>
            </select>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>Fecha inicio</label>
              <input
                disabled
                v-model="form.fecha"
                type="datetime"
                class="form-control form-control-sm"
                placeholder="Fecha"
              />
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label>Fecha fin</label>
              <input
                disabled
                v-model="form.fecha_fin"
                :min="form.fecha"
                type="datetime"
                class="form-control form-control-sm"
                placeholder="Fecha fin"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>Contratista</label>
            <input
              type="text"
              class="form-control form-control-sm"
              disabled
              v-model="form.contrata"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="separator separator-dashed mt-3 mb-3"></div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="4">
            Estado actual
            <input
              type="text"
              :class="
                'form-control form-control-sm mt-2' +
                (form.status == 2 ? ' is-valid' : ' is-invalid')
              "
              readonly
              :value="statusName"
            />
          </b-col>
          <b-col md="4">
            <label>Estado</label>
            <select class="form-control form-control-sm" v-model="status">
              <option :value="null">Seleccione</option>
              <option :value="2">Aprobar</option>
              <option :value="3">No Aprobar</option>
            </select>
          </b-col>
        </b-row>
        <div class="separator separator-dashed mt-3 mb-3"></div>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <div class="form-group">
              <button
                class="btn btn-sm btn-circle"
                @click="
                  () => {
                    $router.go(-1);
                  }
                "
              >
                <i class="la la-reply"></i> Cancelar
              </button>
              <button class="btn btn-sm btn-success" @click="updateTurn()">
                <i class="la la-save"></i>
                {{ loading ? "Cargando..." : "Autorizar turno" }}
              </button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      journey: [],
      status: null,
      form: {
        id: null,
        cod: null,
        placa: null,
        descripcion: null,
        fecha: null,
        fecha_fin: null,
        tipo: null,
        contrata: null,
        status: null,
      },
      statusName: null,
    };
  },
  mounted() {
    this.loadJourn();
    this.loadTurn(this.id);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    loadJourn() {
      axios
        .get(`/tipo-jornadas`)
        .then((res) => {
          this.journey = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    loadTurn(id) {
      this.status = null;
      axios
        .get(`/turns/${id}`)
        .then((res) => {
          const [data] = res.data;
          this.form = data;
          this.form.status = data.estado.id;
          this.form.id = id;
          this.form.tipo = data.tipo_jornada_id;
          this.form.cod = data.codigo;
          this.statusName = data.estado.estado;
          this.form.descripcion = data.observacion
            .split("-")
            .join(" y ")
            .split("|")
            .join(" a ");
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateTurn() {
      if (this.status) {
        const config = {
          ids: [this.form.id],
          estado_id: this.status,
          user: this.user.usuario,
        };
        axios
          .post(`/turns/approvals`, config)
          .then((res) => {
            if (res.data.message == "success") {
              this.loadTurn(this.id);
              toastr.success(res.data.information);
              this.loading = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        toastr.info("Debe seleccionar un estado...");
      }
    },
  },
};
</script>
